import { faFilter } from "@fortawesome/free-solid-svg-icons";
import PopupU from '../../../../../../components/ui/PopupU';
import { useTranslation } from "react-i18next";

const Filter = ({...props}) => {
    const { t } = useTranslation();

    const handleCreatedAtLb = (event) => {
        props.setQuery(prev => {
            return {...prev, filter: {...prev.filter, createdAtLb: event.target.value}}
        });
    }

    const handleCreatedAtUb = (event) => {
        props.setQuery(prev => {
            return {...prev, filter: {...prev.filter, createdAtUb: event.target.value}}
        });
    }

    const clearFilter = () => {
        props.setQuery(prev => {
            return {...prev, 
                filter: {...prev.filter, 
                    createdAtLb: '',
                    createdAtUb: ''
                }}
        })
    }

    return (
        <PopupU border={'border-dark'} icon={faFilter} className={'small px-2'}>
            <div className="d-flex flex-column px-2 py-1">
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <span className="bold font-14 fg-dark">{t('FILTER')}</span>
                    <span onClick={clearFilter} className="fg-red-300 font-12 hover-cursor"><u>{t('CLEAR')}</u></span>
                </div>
                <div className="d-flex flex-column">
                    <span className="font-12 medium fg-gray mt-2">{t('REGISTRATION_DATE')}</span>
                    <div className="d-flex flex-row mt-1">
                        <input type="date"
                            value={props.query.filter.createdAtLb} 
                            onChange={handleCreatedAtLb}
                            className="border px-1 rounded font-12"/>
                        <span className="mx-2">-</span>
                        <input type="date"
                            value={props.query.filter.createdAtUb}
                            onChange={handleCreatedAtUb}
                            className="border px-1 rounded font-12"/>
                    </div>
                </div>
            </div>
        </PopupU>
    );
}

export default Filter;